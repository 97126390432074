import React from 'react';
import CO2LandingPage from './CO2LandingPage';

function App() {
    return (
        <div className="App">
            <CO2LandingPage />
        </div>
    );
}

export default App;